import React, { Suspense, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ConditionalRoute from "@whitecobalt/tungsten/ConditionalRoute";
import ErrorBoundary from "@whitecobalt/tungsten/ErrorBoundary";
import { useSession, SessionActionType } from "@whitecobalt/tungsten/Session";
import { Landing } from "./pages";
import PageLoader from "@components/Loader";
import branding from "@config/branding";
import ErrorPage404 from "./pages/Error/404";
import AuthRoutes from "./pages/Auth";
import CustomPublic from "./pages/CustomPublic";
import Layout from "@components/Layout";
import UserLayout from "@components/UserLayout";

const Routes = () => {
    const [{user, loading }, dispatch] = useSession();
	const hasAuthenthication = !!user
    
	const handleRedirect = (redirected: boolean, props: any) => {
		if (redirected) {
			dispatch({
				type: SessionActionType.SET_REDIRECT,
				payload: props.location.pathname + props.location.search
			})
		}
    }

    useEffect(() => {
        document.body.setAttribute('data-auth', (!!user).toString())
    }, [user])

    if(loading) return (
		<PageLoader />
    )
    
	return (
        <Switch>
            {/* <Route path="/auth" component={AuthRoutes}/> */}
            {/* <Route path="/public" component={CustomPublic}/> */}
            <Route
                path="/">
                <Layout>
                    {/**@ts-ignore */}
                    <ErrorBoundary>
                        <Suspense 
                            fallback={(
                                <PageLoader />
                            )}
                        >
                            <Switch>
                                <Route path="/" component={Landing} />
                                {/* <Redirect path="/" to="/auth/login" exact/> */}
                                <Route path="*" component={ErrorPage404}/>
                            </Switch>
                        </Suspense>
                    </ErrorBoundary>
                </Layout>
            </Route>
        </Switch>
	);
}

export default Routes