import { PricingDataResponseType } from "@types";
import { createStore, useStore, useStoreSelectorState } from "shomai"

type BrandingText = {
    brandingTextTypeID: number,
    brandingTextPageID: number,
    page: null | string,
    contents: string;
}

export interface ThemeStore {
    footerHTML: string;
    headerHTML: string;
    applicationAreaID: number | null;
    applicationArea?: {
        description: string;
        priceListURL: string;
        privacyPolicyURL: string;
        termsURL: string;
        footerHTML: string;
        headerHTML: string;
        emailSenderName: string;
        emailSenderEmail: string;
    };
    cssContent: string;
    cssurl: string | null;
    logoURL: string | null;
    favIcon: string | null;
    name: string | null;
    browserTitle: string | null;
    colours: {
        colourID: number,
        colourDescription: string,
        hexCode: string
    }[];
    text: BrandingText[];
    textByTypeID: Record<string, BrandingText>;
    pricing: PricingDataResponseType | null;
}

export const themeStore = createStore<ThemeStore>({
    headerHTML: '',
    footerHTML: '',
    applicationAreaID: null,
    cssContent: '',
    cssurl: null,
    logoURL: null,
    favIcon: null,
    name: null,
    browserTitle: null,
    colours: [],
    text: [],
    textByTypeID: {},
    pricing: null
})

export const useThemeStore = (): [ThemeStore, typeof themeStore.setState] => {
    return [useStore(themeStore, (state) => state), themeStore.setState]
}

export const useThemeStoreSelector = (selector: keyof ThemeStore) => useStoreSelectorState(themeStore, selector)