import React, { useLayoutEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { useBrandingScreenTitle } from '@hooks/useBrandingScreenTitle'
import browserAgent from '@whitecobalt/tungsten/utils/browserAgent'
import './index.scss'

interface NotFoundProps {
}

const NotFound: React.FunctionComponent<NotFoundProps> = ({}) => {

    useLayoutEffect(() => {
        const root = document.getElementById('root')

        root?.classList.add('no-half-bg')

        return () => root?.classList.remove('no-half-bg')
    }, [])

    useBrandingScreenTitle('404 - page not found')
    
    return (
        <Container className={`d-flex justify-content-center item-center`}>
            <div className="page-not-found">
                <h1 className='error-color' >404</h1>
                <h3>Page not found</h3>
            </div>
        </Container>
    )
}

export default NotFound