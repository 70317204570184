import React from 'react';
import { useThemeStore } from '@services/store/theme';

interface Layout {

}

const Layout: React.FunctionComponent<Layout> = ({ children }) => {
    const [theme] = useThemeStore()

	return (
		<>
            <div className="body-light"></div>
            {/* <Header /> */}
            <div dangerouslySetInnerHTML={{__html: theme.headerHTML}} />
            <main>
                {children}
            </main>
            <div dangerouslySetInnerHTML={{__html: theme.footerHTML}} />
            {/* <Footer /> */}
		</>
	);
}

export default Layout;