import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Routes from './Routes';
import { useLinearToken } from "@whitecobalt/tungsten/hooks/useLinearToken";
import { useReceiveUser } from '@whitecobalt/tungsten/hooks/useReceiveUser';
import { useUpdateApplication } from "@whitecobalt/tungsten/hooks/useUpdateApplication";
import branding from '@config/branding';
import { useTracking } from "@hooks/useTracking";
import cookie from '@whitecobalt/tungsten/esm/common/utils/cookie';

const Application = () => {
	const location = useLocation()

	useLinearToken();
	useReceiveUser();
	useUpdateApplication(branding.services.env === "production" ? 15 : 2);
	useTracking()

	useEffect(() => {
		if(document?.documentElement) {
			document.documentElement.scrollTop = 0
		}
	}, [location.pathname])

	return (
		<>
			<Routes />
		</>
	);
}

export default Application;